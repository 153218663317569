import axios from 'axios';
import { useEffect } from 'react';


function App() {
	var href = window.location.href.split('/')
	var link = href[href.length-1]

	useEffect(() => {
	  axios.get('https://api.optongroup.ru/linker/get_link?link='+link)
	    .then(response => {
			window.location.replace(response.data.to_link[0]);
	    })
	    .catch(error => {
			window.location.replace('https://t.me/OptonGroup_contact');
	    });
	}, []);
	
	return (
		<>
			
		</>
	);
}

export default App;
